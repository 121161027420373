import { useState, useEffect } from 'react';
import axios from 'axios';
import BottomBar from './BottomBar';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Accordion, Button, Form, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import ReactHtmlParser from 'react-html-parser';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from 'react-ga';

const regionSetInfos = [
    {
        regionSetName: '홍대 메이저',
        region: '홍대',
        milongaNames: [
            '오나다',
            '오초',
            '루쓰',
            '오뜨라',
            '라벤따나',
            '안단테',
            '프리스타일',
        ],
    },
    {
        regionSetName: '홍대 전체',
        region: '홍대',
        milongaNames: [
            '오나다',
            '오초',
            '루쓰',
            '오뜨라',
            '라벤따나',
            '안단테',
            '프리스타일',
            '솔땅',
            '홍턴',
            '브루호',
            '오나다2',
        ],
    },
    {
        regionSetName: '강남',
        region: '강남',
        milongaNames: [
            '엘땅고',
            '또도땅고',
            '올어바웃',
            '엔빠스',
            '클럽판땅고',
            '마젠타',
            '라윰바',
        ],
    },
    {
        regionSetName: '테스트용',
        region: '테스트용',
        milongaNames: ['독도', '울릉도', '마라도', '홍도', '완도', '뚝섬'],
    },
];

const reportMilongaStatus = (region, milongaName, metricName, value) => {
    const url = 'https://milonga.hanmesoft.com/status_server/report.php';
    // The following is to fix the post params not being set as $_POST in php code.
    // Reference: https://github.com/axios/axios/issues/1195.
    const params = new URLSearchParams();
    params.append('region', region);
    params.append('milongaName', milongaName);
    params.append('metricName', metricName);
    params.append('value', value);
    axios
        .post(url, params)
        .then((response) => {
            const responseData = response.data;
            //            console.log({ responseData });
        })
        .catch((error) => {
            console.log({ error });
        });
};

const elapsedTime = (date) => {
    const start = new Date(date);
    const end = new Date();

    const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
    if (seconds < 60) return ['방금전', seconds];

    const minutes = seconds / 60;
    if (minutes < 60) return [`${Math.floor(minutes)}분전`, seconds];

    const hours = minutes / 60;
    if (hours < 24) return [`${Math.floor(hours)}시간전`, seconds];

    const days = hours / 24;
    if (days < 7) return [`${Math.floor(days)}일전`, seconds];

    return [`${start.toLocaleDateString()}`, seconds];
};

const formDate = (fmt, date) => {
    if (!date) {
        date = new Date();
    }

    const opt = {
        YYYY: date.getFullYear().toString(),
        mm: (date.getMonth() + 1).toString(),
        dd: date.getDate().toString(),
        HH: date.getHours().toString(),
        MM: date.getMinutes().toString(),
        SS: date.getSeconds().toString(),
    };

    for (let k in opt) {
        const reg = new RegExp('(' + k + ')');
        const match = reg.exec(fmt);
        if (match) {
            fmt = fmt.replace(
                match[1],
                match[1].length == 1
                    ? opt[k]
                    : opt[k].padStart(match[1].length, '0')
            );
        }
    }

    return fmt;
};

const MilongaStatusCard = ({
    uniqueId,
    region,
    milongaName,
    eventInfoText,
    currentMilongaStatus,
    statusChangeCallbackFunction,
    ...props
}) => {
    const milongaNameSpan = (
        <span className="status-milonga-name-span">
            <strong>{milongaName}</strong>:
        </span>
    );
    const [sexRatio, setSexRatio] = useState(null);
    const [crowdingLevel, setCrowdingLevel] = useState(null);
    const [updatedAt, setUpdatedAt] = useState(null);
    const [accordionIsOpen, setAccordionIsOpen] = useState(false);

    //    console.log('MilongaStatusCard', {
    //        uniqueId,
    //        region,
    //        milongaName,
    //        currentMilongaStatus,
    //        sexRatio,
    //        crowdingLevel,
    //    });
    let sexRatioColor, sexRatioText;
    if (sexRatio === null) {
        [sexRatioColor, sexRatioText] = ['gray', '정보없음'];
    } else if (sexRatio === 0) {
        [sexRatioColor, sexRatioText] = ['green', '성비맞음'];
    } else if (sexRatio > 0) {
        [sexRatioColor, sexRatioText] = [
            'blue',
            sexRatio >= 20 ? '완전남탕' : '남 +' + Math.abs(sexRatio),
        ];
    } else {
        [sexRatioColor, sexRatioText] = [
            'red',
            sexRatio <= -20 ? '완전여탕' : '여 +' + Math.abs(sexRatio),
        ];
    }
    const sexRatioSpan = (
        <span
            className="status-sex-ratio-span"
            style={{
                color: sexRatioColor,
            }}
        >
            {sexRatioText}
        </span>
    );

    const sliderMarksStyles = {
        red: {
            marginTop: 8,
            fontSize: '16px',
            color: 'red',
            wordBreak: 'keep-all',
        },
        green: {
            marginTop: 6,
            fontSize: '20px',
            color: 'green',
            wordBreak: 'keep-all',
        },
        blue: {
            marginTop: 8,
            fontSize: '16px',
            color: 'blue',
            wordBreak: 'keep-all',
        },
        default: {
            marginTop: 8,
            fontSize: '16px',
            wordBreak: 'keep-all',
        },
    };
    const sexRatioSliderMarks = {
        '-20': {
            style: sliderMarksStyles['red'],
            label: '여탕',
        },
        '-15': {
            style: sliderMarksStyles['red'],
            label: '+15',
        },
        '-10': {
            style: sliderMarksStyles['red'],
            label: '+10',
        },
        '-5': {
            style: sliderMarksStyles['red'],
            label: '+5',
        },
        0: {
            style: sliderMarksStyles['green'],
            label: '😀',
        },
        5: {
            style: sliderMarksStyles['blue'],
            label: '+5',
        },
        10: {
            style: sliderMarksStyles['blue'],
            label: '+10',
        },
        15: {
            style: sliderMarksStyles['blue'],
            label: '+15',
        },
        20: {
            style: sliderMarksStyles['blue'],
            label: '남탕',
        },
    };
    const crowdingLevelSliderMarks = {
        '-2': {
            style: { color: '#000', ...sliderMarksStyles['default'] },
            label: '혼잡',
        },
        '-1': {
            style: { color: '#222', ...sliderMarksStyles['default'] },
            label: '약간혼잡',
        },
        0: {
            style: { color: '#444', ...sliderMarksStyles['default'] },
            label: '인원적당',
        },
        1: {
            style: { color: '#666', ...sliderMarksStyles['default'] },
            label: '약간한산',
        },
        2: {
            style: { color: '#888', ...sliderMarksStyles['default'] },
            label: '한산',
        },
    };

    let crowdingLevelColor, crowdingLevelText;
    if (crowdingLevel === null) {
        [crowdingLevelColor, crowdingLevelText] = ['gray', '-'];
    } else {
        [crowdingLevelColor, crowdingLevelText] = [
            crowdingLevelSliderMarks[crowdingLevel].style.color,
            crowdingLevelSliderMarks[crowdingLevel].label,
        ];
    }
    const crowdingLevelSpan = (
        <span
            className="status-crowding-level-span"
            style={{
                color: crowdingLevelColor,
            }}
        >
            {crowdingLevelText}
        </span>
    );

    const accordionElemId = 'accordion_id_' + uniqueId;
    const accordionElement = document.getElementById(accordionElemId);

    //    const accordionIsOpen =
    //        accordionElement && accordionElement.classList.contains('show');

    const sexRatioValueOnChange = (value) => {
        setSexRatio(value);
        reportMilongaStatus(region, milongaName, 'sexRatio', value);
        statusChangeCallbackFunction(region, milongaName, value, crowdingLevel); // Use the latest value of 'value'.
    };
    const crowdingLevelValueOnChange = (value) => {
        setCrowdingLevel(value);
        reportMilongaStatus(region, milongaName, 'crowdingLevel', value);
        statusChangeCallbackFunction(region, milongaName, sexRatio, value); // Use the latest value of 'value'.
    };

    if (!accordionIsOpen) {
        const key = `${region}_${milongaName}`;
        let cms = {
            sexRatio: null,
            crowdingLevel: null,
            updatedAt: null,
        };
        if (currentMilongaStatus[key] !== undefined) {
            cms = currentMilongaStatus[key];
        }
        if (sexRatio !== cms.sexRatio) {
            setSexRatio(cms.sexRatio);
        }
        if (crowdingLevel !== cms.crowdingLevel) {
            setCrowdingLevel(cms.crowdingLevel);
        }
        if (updatedAt !== cms.updatedAt) {
            setUpdatedAt(cms.updatedAt);
        }
    }

    const [elapsedTimeText, elapsedSeconds] =
        updatedAt === null ? ['', 999999] : elapsedTime(updatedAt);

    const cardBorderClass =
        elapsedSeconds <= 20
            ? 'border-custom-1'
            : accordionIsOpen
            ? 'border-custom-2 m-2 my-0'
            : 'border-success m-2 my-0';

    const cardBgClass =
        elapsedSeconds <= 20
            ? 'custom-1'
            : accordionIsOpen
            ? 'custom-2'
            : 'light';

    return (
        <Card bg={cardBgClass} className={cardBorderClass + ' m-2 my-0'}>
            <Card.Body className="py-0 px-1">
                <Accordion>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div className="status-text-area-container">
                            {eventInfoText === null ? (
                                <div className="status-no-event-container">
                                    {milongaNameSpan}
                                    {sexRatioSpan}
                                    {crowdingLevelSpan}
                                    <span className="status-elapsed-time-span">
                                        {elapsedTimeText}
                                    </span>
                                </div>
                            ) : (
                                <>
                                    <div className="mt-1">
                                        {milongaNameSpan}
                                        {sexRatioSpan}
                                        {crowdingLevelSpan}
                                        <span className="status-elapsed-time-span">
                                            {elapsedTimeText}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="status-event-info-span">
                                            {eventInfoText}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="status-update-button-container">
                            <Accordion.Toggle
                                as={Button}
                                variant={accordionIsOpen ? 'success' : 'info'}
                                className="ml-1 mr-0 my-2"
                                eventKey="0"
                                onClick={() => {
                                    setAccordionIsOpen(!accordionIsOpen);
                                }}
                            >
                                {accordionIsOpen ? '완료' : '변경'}
                            </Accordion.Toggle>
                        </div>
                    </div>
                    <Accordion.Collapse
                        id={accordionElemId}
                        className="px-3"
                        eventKey="0"
                    >
                        <div>
                            <div className="m-1 p-1"></div>
                            <Slider
                                min={-20}
                                max={20}
                                step={5}
                                dots={true}
                                value={sexRatio === null ? 0 : sexRatio}
                                defaultValue={sexRatio === null ? 0 : sexRatio}
                                startPoint={0}
                                onChange={sexRatioValueOnChange}
                                trackStyle={{
                                    backgroundColor: '#888',
                                    height: 4,
                                }}
                                //railStyle={{ backgroundColor: 'red', height: 4 }}
                                handleStyle={{
                                    borderColor: '#000',
                                    backgroundColor: 'gray',
                                    borderWidth: 2,
                                }}
                                marks={sexRatioSliderMarks}
                                //                            customHandle={<CustomHandle2 />}
                                handleStyle={{
                                    borderColor: '#333',
                                    borderWidth: 4,
                                    height: 34,
                                    width: 18,
                                    marginLeft: 0,
                                    marginTop: -15,
                                    borderRadius: 10,
                                    backgroundColor: '#777',
                                    opacity: 1.0,
                                }}
                            />
                            <div className="m-3 p-3"></div>
                            <Slider
                                min={-2}
                                max={2}
                                step={1}
                                dots={true}
                                value={
                                    crowdingLevel === null ? 0 : crowdingLevel
                                }
                                defaultValue={
                                    crowdingLevel === null ? 0 : crowdingLevel
                                }
                                startPoint={0}
                                onChange={crowdingLevelValueOnChange}
                                trackStyle={{
                                    backgroundColor: '#888',
                                    height: 4,
                                }}
                                handleStyle={{
                                    borderColor: '#000',
                                    backgroundColor: 'gray',
                                    borderWidth: 2,
                                }}
                                marks={crowdingLevelSliderMarks}
                                handleStyle={{
                                    borderColor: '#333',
                                    borderWidth: 4,
                                    height: 34,
                                    width: 18,
                                    marginLeft: 0,
                                    marginTop: -15,
                                    borderRadius: 10,
                                    backgroundColor: '#777',
                                    opacity: 1.0,
                                }}
                            />
                            <div className="m-3 p-2"></div>
                            <p
                                className="m-0 p-1"
                                style={{
                                    fontSize: '12px',
                                    wordBreak: 'keep-all',
                                }}
                            >
                                <strong>
                                    상황 리포트는 정직하고 정확하게 해주시길
                                    부탁드립니다!
                                </strong>
                            </p>
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </Card.Body>
        </Card>
    );
};

const formatNumber = (n, minNumOfDigits) => {
    const text = '0'.repeat(minNumOfDigits - 1) + n.toString();
    return n.toString().length > minNumOfDigits
        ? text
        : text.substring(text.length - minNumOfDigits);
};

const formatDate = (d) => {
    return (
        d.getFullYear().toString() +
        '-' +
        formatNumber(d.getMonth() + 1, 2) +
        '-' +
        formatNumber(d.getDate(), 2)
    );
};

const formatTime = (d) => {
    return (
        formatNumber(d.getHours(), 2) + ':' + formatNumber(d.getMinutes(), 2)
    );
};

const getNthDayOfWeekInMonth = (d) => {
    // Reference: https://m.blog.naver.com/sabjili/30096802129
    return Math.ceil(d.getDate() / 7);
};

const getLastDayOfMonth = (d) => {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
};

function App() {
    //    ReactGA.initialize('UA-185250602-4');
    //    ReactGA.pageview(window.location.pathname + window.location.search);

    const [mode, setMode] = useState('status');

    const [regionSet, setRegionSet] = useState(
        localStorage.getItem('savedRegionSet') === null
            ? regionSetInfos[0].regionSetName
            : localStorage.getItem('savedRegionSet')
    );
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);
    const [currentMilongaStatus, setCurrentMilongaStatus] = useState({});
    const [eventInfos, setEventInfos] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const cutoffHour = 7; // Consider 'today' as until 7 a.m. tomorrow.

                const today = new Date();
                today.setTime(today.getTime() - cutoffHour * 60 * 60 * 1000);
                const tomorrow = new Date(today); // Clone date.
                tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000); // Advance by 24 hours.

                const minTime = formatDate(today) + 'T00:00:00Z';
                const maxTime = formatDate(tomorrow) + 'T00:00:00Z';
                //                console.log({today, minTime});
                //                console.log({tomorrow, maxTime});

                const calendarId =
                    'e2dacde651c1bfd2b70d9e21754e76766c6716949d389ed937594e644250d2e5@group.calendar.google.com';
                const googleCalendarApiKey =
                    'AIzaSyA3MyewOor1HxlQNotrO5sWIpU94nX_bpA';
                const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?orderBy=startTime&singleEvents=true&timeMax=${maxTime}&timeMin=${minTime}&key=${googleCalendarApiKey}`;

                const response = await fetch(url);
                const data = await response.json();

                const newEventInfos = {};
                for (let itemInfo of data.items) {
                    const arr = itemInfo.summary.split(' ');
                    const milongaName = arr.shift();
                    const eventName = arr.join(' ');

                    const startTime = formatTime(
                        new Date(itemInfo.start.dateTime)
                    );
                    const endTime = formatTime(new Date(itemInfo.end.dateTime));
                    const keyValueProperties = {};
                    if (
                        itemInfo.description !== undefined &&
                        itemInfo.description.trim() !== ''
                    ) {
                        for (let line of itemInfo.description
                            .trim()
                            .split('\n')) {
                            line = line.trim();
                            let matches;
                            matches = line.match(/^([^:]+):\s*(\S.*)$/);
                            if (matches !== null) {
                                keyValueProperties[matches[1].trim()] =
                                    matches[2].trim();
                            }
                        }
                    }
                    let eventInfoText = `${eventName} (${startTime}~${endTime})`;
                    //                    console.log({milongaName, eventInfoText});
                    if (keyValueProperties['DJ'] !== undefined) {
                        const djName = keyValueProperties['DJ'];
                        eventInfoText += ` DJ: ${djName}`;
                    }
                    if (newEventInfos[milongaName] === undefined) {
                        newEventInfos[milongaName] = [eventInfoText];
                    } else {
                        newEventInfos[milongaName].push(eventInfoText);
                    }
                }
                setEventInfos(newEventInfos);
            } catch (err) {
                //                console.log(err);
            } finally {
            }
        })();

        const fetchStatusData = async () => {
            try {
                const response = await fetch(
                    'https://milonga.hanmesoft.com/status_server/fetchStatus.php'
                );
                const newData = await response.json();
                const newMilongaStatus = {};
                for (let status of newData.milongaStatus) {
                    const key = `${status.region}_${status.milongaName}`;
                    newMilongaStatus[key] = {
                        sexRatio: parseInt(status.sexRatio),
                        crowdingLevel: parseInt(status.crowdingLevel),
                        updatedAt: status.updated_at,
                    };
                }
                setCurrentMilongaStatus(newMilongaStatus);
                //                console.log('fetched');
            } catch (err) {
                //                console.log(err);
            } finally {
            }
        };

        fetchStatusData();

        // Set up interval for periodic fetching
        const intervalId = setInterval(fetchStatusData, 10000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    //    console.log('entered', { currentMilongaStatus });

    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = text;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };

    const statusChangeCallbackFunction = (
        region,
        milongaName,
        sexRatio,
        crowdingLevel
    ) => {
        const updatedMilongaStatus = currentMilongaStatus;

        const key = `${region}_${milongaName}`;

        updatedMilongaStatus[key] = {
            sexRatio: sexRatio,
            crowdingLevel: crowdingLevel,
            updatedAt: formDate('YYYY-mm-dd HH:MM:SS', new Date()),
        };

        showSnackbar();
    };

    const selectedRegionSet = regionSetInfos.filter(
        (value) => value.regionSetName === regionSet
    )[0];

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-5 mt-4 mb-2">
                            <a
                                href="https://milonga.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                밀롱가 현재상황
                            </a>
                        </h1>
                        <Form.Control
                            as="select"
                            className="mx-auto"
                            value={regionSet}
                            onChange={(e) => {
                                localStorage.setItem(
                                    'savedRegionSet',
                                    e.target.value
                                );
                                //setRegionSet(e.target.value);
                                // Reload the current page to make sure that all accordions are closed.
                                window.location.reload();
                            }}
                            style={{
                                width: '240px',
                                textAlign: 'center',
                                textAlignLast: 'center',
                            }}
                        >
                            {regionSetInfos.map((value, index) => {
                                return (
                                    <option value={value.regionSetName}>
                                        {'지역선택: ' + value.regionSetName}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        {selectedRegionSet.regionSetName === '테스트용' ? (
                            <p className="m-2">
                                <span
                                    style={{
                                        color: 'blue',
                                    }}
                                >
                                    테스트용입니다. 마음놓고 바꿔 보세요~
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="col-sm-1 col-md-2 col-xl-3 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-8 col-xl-6 m-0 py-0 px-1">
                        {selectedRegionSet.milongaNames.map(
                            (milongaName, index) => {
                                let eventInfoText = null;
                                if (
                                    eventInfos[milongaName] !== undefined &&
                                    eventInfos[milongaName].length > 0
                                ) {
                                    eventInfoText = eventInfos[milongaName].map(
                                        (eventInfo, index) => {
                                            return (
                                                <>
                                                    {eventInfo}
                                                    <br />
                                                </>
                                            );
                                        }
                                    );
                                }

                                return (
                                    <MilongaStatusCard
                                        uniqueId={index + 1}
                                        region={selectedRegionSet.region}
                                        milongaName={milongaName}
                                        eventInfoText={eventInfoText}
                                        currentMilongaStatus={
                                            currentMilongaStatus
                                        }
                                        statusChangeCallbackFunction={
                                            statusChangeCallbackFunction
                                        }
                                    />
                                );
                            }
                        )}
                    </div>
                    <div className="col-sm-1 col-md-2 col-xl-3 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;리포트해 주셔서 감사합니다.
                </div>
            </ReactSnackBar>
            <BottomBar />
        </>
    );
}

export default App;
